/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { verifyEmail } from 'services/auth.service';
import { SET_LOGIN_MENU, SET_SNACK_BAR, SET_LOGIN_DATA } from 'store/actions';


const BRDocsVerifyEmail = ({ modal, setMode, email, ...others }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const closeModal = () => dispatch({ type: SET_LOGIN_MENU, opened: false });
    const handleSuccess = () => {
        dispatch({ type: SET_SNACK_BAR, state: { open: true, type: 'success', message: "Cadastro concluído com sucesso!" } });
        if (!modal) {
            const redirectUrl = sessionStorage.getItem('redirectUrl');
            if (redirectUrl) {
                sessionStorage.removeItem('redirectUrl');
                window.location = redirectUrl;
            } else {
                window.location = '/';
            }
        } else {
            closeModal();
            dispatch({ type: SET_LOGIN_DATA });
        }
    };
    const handleLoginErr = (err) => {
        process.env.NODE_ENV !== 'production' && console.error(err);
        dispatch({ type: SET_SNACK_BAR, state: { open: true, type: 'error', message: err.msg } });
    };
    const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
            await verifyEmail({ ...values, email });
            handleSuccess();
            setStatus({ success: true });
        } catch (err) {
            handleLoginErr(err);
            setErrors({ submit: err.msg });
            setStatus({ success: false });
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <>
            <Formik
                initialValues={{
                    verifyPin: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    verifyPin: Yup.string().max(255).required('PIN é obrigatório')
                })}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Typography>
                            Um PIN de verificação foi enviado para o seu e-mail. Para concluir o cadastro, insira-o abaixo.
                        </Typography>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.verifyPin && errors.verifyPin)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-verifyPin-login">PIN</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-verifyPin-login"
                                value={values.senha}
                                name="verifyPin"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                label="PIN"
                                inputProps={{}}
                            />
                            {touched.verifyPin && errors.verifyPin && (
                                <FormHelperText error id="standard-weight-helper-text-verifyPin-login">
                                    {errors.verifyPin}
                                </FormHelperText>
                            )}
                        </FormControl>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Continuar
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default BRDocsVerifyEmail;
