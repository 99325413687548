import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateCNPJ, validateCPF } from 'utils/utils';
import { useGoogleLogin } from '@react-oauth/google';
import { useTheme } from '@mui/material/styles';
import InputMask from 'react-input-mask';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

import * as Yup from 'yup';
import { Formik } from 'formik';

import useScriptRef from 'hooks/useScriptRef';
import Google from 'assets/images/icons/social-google.svg';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Signup, googleLogin } from 'services/auth.service';
import { SET_LOGIN_DATA, SET_LOGIN_MENU, START_LOGIN_FUNC } from 'store/actions';

const cpfMask = '999.999.999-99';
const cnpjMask = '99.999.999/9999-99';
const phoneMask = '(99) 99999-9999';

const BRDocsRegister = ({ modal, setMode, setEmailVerify, ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const dispatch = useDispatch();
    const loginSel = useSelector((state) => state.login);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const customization = useSelector((state) => state.customization);
    const [showPassword, setShowPassword] = useState(false);
    const [checked, setChecked] = useState(true);
    const [cpfCnpjMask, setCpfCnpjMask] = useState(cpfMask);

    const [strength, setStrength] = useState(0);
    const [level, setLevel] = useState();

    const closeModal = () => dispatch({ type: SET_LOGIN_MENU, opened: false });
    const googleHandler = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            const token = tokenResponse;
            process.env.NODE_ENV !== 'production' && console.log(token);
            const { data } = await googleLogin(token);
            handleSuccess(data);
        },
        onError: (err) => {
            handleLoginErr(err);
        }
    });

    const handleSuccess = (data) => {
        sessionStorage.setItem('accessToken', data.accessToken);
        sessionStorage.setItem('refreshToken', data.refreshToken);
        process.env.NODE_ENV !== 'production' && console.log(data);


        // Adicionando um console.log para exibir o ID do cliente criado

        if (!modal) {
            const redirectUrl = sessionStorage.getItem('redirectUrl');
            if (redirectUrl) {
                sessionStorage.removeItem('redirectUrl');
                window.location = redirectUrl;
            } else {
                window.location = '/';
            }
        } else {
            setMode('verifyEmail');
        }
    };

    const handleLoginErr = (err) => {
        process.env.NODE_ENV !== 'production' && console.error(err);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const changePassword = (value) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
            const { data } = await Signup(values);
            setEmailVerify(values.email)
            handleSuccess(data);

            // Adicionando um console.log para verificar os dados enviados
            console.log("Dados enviados:", values);

            setStatus({ success: true });
            setSubmitting(false);
        } catch (err) {
            handleLoginErr(err);

            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
        }
    };


    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <AnimateButton>
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={googleHandler}
                            size="large"
                            sx={{
                                color: 'grey.700',
                                backgroundColor: theme.palette.grey[50],
                                borderColor: theme.palette.grey[100]
                            }}
                        >
                            <Box sx={{ mr: { xs: 1, sm: 2, width: 20 } }}>
                                <img src={Google} alt="google" width={16} height={16} style={{ marginRight: matchDownSM ? 8 : 16 }} />
                            </Box>
                            Cadastrar com Google
                        </Button>
                    </AnimateButton>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                        <Button
                            variant="outlined"
                            sx={{
                                cursor: 'unset',
                                m: 2,
                                py: 0.5,
                                px: 7,
                                borderColor: `${theme.palette.grey[100]} !important`,
                                color: `${theme.palette.grey[900]}!important`,
                                fontWeight: 500,
                                borderRadius: `${customization.borderRadius}px`
                            }}
                            disableRipple
                            disabled
                        >
                            OU
                        </Button>
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Cadastrar com endereço de E-mail</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Formik
                initialValues={{
                    nome: '',
                    documento: '',
                    email: '',
                    senha: '',
                    telefone: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('Deve ser um e-mail válido').max(255).required('E-mail é um campo obrigatório'),
                    senha: Yup.string().max(255).required('Senha é um campo obrigatório'),
                    documento: Yup.string()
                        .required('CPF/CNPJ é um campo obrigatório!')
                        .test('cpf-cnpj', 'CPF/CNPJ inválido', (value) => {
                            return validateCNPJ(value) || validateCPF(value);
                        }),
                    nome: Yup.string().max(255).required('Nome é um campo obrigatório!'),
                    telefone: Yup.string().max(255).required('Telefone é um campo obrigatório!')
                })}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <Grid container spacing={matchDownSM ? 0 : 2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-name-register">Nome</InputLabel>
                                    <OutlinedInput
                                        id="outlined-name"
                                        type="text"
                                        value={values.nome}
                                        name="nome"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        inputProps={{}}
                                    />
                                    {touched.nome && errors.nome && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.nome}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    error={Boolean(touched.documento && errors.documento)}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-name-register">CPF/CNPJ</InputLabel>
                                    <InputMask
                                        mask={cpfCnpjMask}
                                        maskPlaceholder=""
                                        value={values.documento}
                                        onChange={(e) => {
                                            e.target.value?.replace(/\D/g, '').length < 11
                                                ? setCpfCnpjMask(cpfMask)
                                                : setCpfCnpjMask(cnpjMask);
                                            handleChange(e);
                                        }}
                                        onBlur={(e) => {
                                            values.documento.replace(/\D/g, '').length === 11 && setCpfCnpjMask(cpfMask);
                                            handleBlur(e);
                                        }}
                                        name="documento"
                                    >
                                        {(inputProps) => (
                                            <OutlinedInput {...inputProps} id="outlined-name" type="text" value={values.documento} />
                                        )}
                                    </InputMask>

                                    {touched.documento && errors.documento && (
                                        <FormHelperText error id="standard-weight-helper-text--register">
                                            {errors.documento}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-register">E-mail</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-email-register"
                                type="email"
                                value={values.email}
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                            {touched.email && errors.email && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.email}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.telefone && errors.telefone)}

                        >


                            <Box>

                                <PhoneInput
                                    country={'br'}
                                    name="telefone"
                                    value={values.telefone}
                                    onChange={(e) => setFieldValue("telefone", e)}
                                    onBlur={handleBlur}
                                    inputStyle={{
                                        width: '100%',
                                        height: '56px',
                                        borderRadius: '4px',
                                    }}
                                />
                            </Box>
                            {touched.telefone && errors.telefone && (
                                <FormHelperText error id="standard-weight-helper-text--register">
                                    {errors.telefone}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(touched.senha && errors.senha)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-register">Senha</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-register"
                                type={showPassword ? 'text' : 'password'}
                                value={values.senha}
                                name="senha"
                                label="Senha"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                    changePassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{}}
                            />
                            {touched.senha && errors.senha && (
                                <FormHelperText error id="standard-weight-helper-text-senha-register">
                                    {errors.senha}
                                </FormHelperText>
                            )}
                        </FormControl>

                        {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Box
                                                style={{ backgroundColor: level?.color }}
                                                sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label === 'Weak'
                                                    ? 'Fraca'
                                                    : level?.label === 'Good'
                                                        ? 'Boa'
                                                        : level?.label === 'Strong'
                                                            ? 'Forte'
                                                            : 'Normal'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )}

                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={(event) => setChecked(event.target.checked)}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography variant="subtitle5">
                                            Concordo com{' '}
                                            <Typography variant="subtitle5" component={Link} to="/termos">
                                                Termos & Condições.
                                            </Typography>
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Cadastrar
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default BRDocsRegister;
